<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="9">
          <CCard>
            <CCardHeader><strong> Stock </strong> Information </CCardHeader>
            <CCardBody>
              <CTabs add-tab-classes="mt-1">
                <CTab active>
                  <template slot="title">
                    <CIcon name="cil-calculator" /> Stock Info
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Stock No"
                          horizontal
                          :readonly="!isNewApplication()"
                          v-model="$v.obj.stockNo.$model"
                          :isValid="checkIfValid('stockNo')"
                          invalidFeedback="This is a required field and must be at least 2 characters"
                        />
                        <CRow
                          form
                          class="form-group"
                          v-if="!isNewApplication()"
                        >
                          <CCol tag="label" sm="3" class="col-form-label">
                            System Registration Date
                          </CCol>
                          <CCol sm="9">
                            <input
                              type="datetime-local"
                              :value="computeSystemRegistrationDate"
                              @change="setSystemRegistrationDate"
                              class="mr-2"
                            />
                          </CCol>
                        </CRow>

                        <CSelect
                          horizontal
                          v-if="!isNewApplication()"
                          :value.sync="selectedArrivalState"
                          :options="arrivalStates"
                          label="Arrival"
                        />
                        <CRow
                          form
                          class="form-group"
                          v-if="!isNewApplication()"
                        >
                          <CCol tag="label" sm="3" class="col-form-label">
                            State
                          </CCol>
                          <CCol sm="9">
                            <CInputCheckbox
                              label="Open"
                              :checked.sync="obj.isOpen"
                            />
                            <CInputCheckbox
                              label="Booking"
                              :checked.sync="obj.isBooked"
                            />
                            <CInputCheckbox
                              label="LOU"
                              :checked.sync="obj.isLou"
                            />
                            <CInputCheckbox
                              label="Sold"
                              :checked.sync="obj.isSold"
                            />
                            <CInputCheckbox
                              label="Cancelled"
                              :checked.sync="obj.isCancelled"
                            />
                          </CCol>
                        </CRow>

                        <!-- <CSelect
                          label="Current Status"
                          horizontal
                          :options="stockStatuses"
                          :value.sync="selectedStockStatusId"
                          v-if="!isNewApplication()"
                          ><template #append>
                            <CButton
                              color="primary"
                              @click="updateStockStatus()"
                            >
                              Update
                            </CButton>
                          </template>
                        </CSelect>

                        <CRow
                          form
                          class="form-group"
                          v-if="!isNewApplication()"
                        >
                          <CCol tag="label" sm="3" class="col-form-label">
                            Status History
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="obj.stockStatusHistories"
                              :fields="stockStatusHistoryFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #status_name="{ item }">
                                <td>
                                  {{ item.stockStatus.name }}
                                </td>
                              </template>

                              <template #date_time="{ item }">
                                <td>
                                  {{ getDisplayDateTime(item.dateTime) }}
                                </td>
                              </template>
                              <template #profile_name="{ item }">
                                <td>
                                  {{ getProfileFullName(item) }}
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow> -->
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>
                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Vehicle Info
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Brand"
                          horizontal
                          readonly
                          v-model="selectedBrand.name"
                        >
                          <template #append>
                            <CButton color="primary" @click="onSearchBrand()">
                              Search
                            </CButton>
                          </template>
                        </CInput>
                        <CInput
                          label="Model"
                          horizontal
                          readonly
                          v-model="selectedModel.name"
                        >
                          <template #append>
                            <CButton color="primary" @click="onSearchModel()">
                              Search
                            </CButton>
                          </template>
                        </CInput>
                        <CInput
                          label="Chasis No"
                          horizontal
                          v-model="obj.vehicle.chasisNo"
                        />
                        <CInput
                          label="Engine No"
                          horizontal
                          v-model="obj.vehicle.engineNo"
                        />
                        <CInput
                          label="Engine Capacity"
                          horizontal
                          v-model="obj.vehicle.engineCapacity"
                        />
                        <CSelect
                          label="Vehicle Type"
                          horizontal
                          :options="vehicleTypes"
                          :value.sync="selectedVehicleTypeId"
                          @change="onVehicleTypeSelect()"
                        >
                        </CSelect>

                        <CSelect
                          label="Month"
                          horizontal
                          :options="[
                            '',
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12',
                          ]"
                          :value.sync="obj.vehicle.month"
                        />
                        <CSelect
                          label="Year"
                          horizontal
                          :options="vehicleYear"
                          :value.sync="obj.vehicle.year"
                        />

                        <CInput
                          label="Color"
                          horizontal
                          v-model="obj.vehicle.color"
                        />
                        <CInput
                          label="External Link"
                          horizontal
                          v-model="obj.vehicle.externalLink"
                        >
                          <template #append>
                            <CButton
                              color="primary"
                              @click="openLink(obj.vehicle.externalLink)"
                              >Open</CButton
                            >
                          </template>
                        </CInput>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Description
                          </CCol>
                          <CCol sm="9">
                            <CTextarea
                              placeholder=""
                              rows="5"
                              v-model="obj.vehicle.description"
                            />
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Images
                          </CCol>
                          <CCol sm="9">
                            <CRow>
                              <CCol
                                v-for="(item, index) in obj.vehicle
                                  .vehiclePhotoList"
                                :key="item.id"
                                xs="12"
                                sm="6"
                                md="4"
                                lg="3"
                                xl="2"
                                class="d-flex justify-content-center position-relative mb-4"
                              >
                                <div class="image-container">
                                  <CImg
                                    :src="getImageUrl(item)"
                                    thumbnail
                                    class="img-fluid"
                                    style="max-width: 100%; cursor: pointer"
                                  />
                                  <!-- Buttons overlay -->
                                  <div class="button-overlay">
                                    <div class="button-row">
                                      <CButton
                                        size="sm"
                                        color="primary"
                                        class="move-button"
                                        @click="moveImageUp(index)"
                                        :disabled="index === 0"
                                      >
                                        ▲
                                      </CButton>
                                      <CButton
                                        size="sm"
                                        color="primary"
                                        class="move-button"
                                        @click="moveImageDown(index)"
                                        :disabled="
                                          index ===
                                          sortedVehiclePhotos.length - 1
                                        "
                                      >
                                        ▼
                                      </CButton>
                                    </div>
                                    <div class="button-row">
                                      <CButton
                                        size="sm"
                                        color="danger"
                                        class="move-button"
                                        @click="deleteImage(item.id)"
                                      >
                                        🗑
                                      </CButton>
                                      <CButton
                                        size="sm"
                                        color="success"
                                        class="move-button"
                                        @click="setDefaultImage(item)"
                                        :disabled="isDefaultImage(item)"
                                      >
                                        Default
                                      </CButton>
                                    </div>
                                    <CButton
                                      size="sm"
                                      color="info"
                                      class="move-button"
                                      @click="maximizeImage(item)"
                                    >
                                      🔍
                                    </CButton>
                                  </div>

                                  <!-- <div class="image-overlay">
                                    <CButton
                                      color="danger"
                                      size="sm"
                                      @click="deleteImage(item.id)"
                                      class="action-button"
                                    >
                                      <CIcon name="cil-trash" />
                                    </CButton>
                                    <CButton
                                      color="success"
                                      size="sm"
                                      @click="setDefaultImage(item)"
                                      class="action-button"
                                      :disabled="isDefaultImage(item)"
                                    >
                                      <CIcon name="cil-star" />
                                    </CButton>
                                    <CButton
                                      color="info"
                                      size="sm"
                                      @click="moveImageUp(index)"
                                      class="action-button"
                                      :disabled="index === 0"
                                    >
                                      <CIcon name="cil-arrow-top" />
                                    </CButton>
                                    <CButton
                                      color="info"
                                      size="sm"
                                      @click="moveImageDown(index)"
                                      class="action-button"
                                      :disabled="
                                        index === sortedVehiclePhotos.length - 1
                                      "
                                    >
                                      <CIcon name="cil-arrow-bottom" />
                                    </CButton>
                                  </div> -->

                                  <span
                                    v-if="isDefaultImage(item)"
                                    class="default-badge"
                                  >
                                    Default
                                  </span>
                                </div>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadImage @uploaded="media_uploaded" />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>
                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Purchase
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Supplier"
                          horizontal
                          readonly
                          v-model="selectedSupplier.name"
                        >
                          <template #append>
                            <CButton
                              color="primary"
                              @click="onSearchSupplier()"
                            >
                              Search
                            </CButton>
                          </template>
                        </CInput>
                        <CInput
                          label="Supplier Currency"
                          horizontal
                          v-model="obj.purchase.supplierCurrency"
                        />
                        <CInput
                          label="Price In Foreign Currency"
                          :prepend="obj.purchase.supplierCurrency"
                          horizontal
                          v-model="obj.purchase.vehiclePriceSupplierCurrency"
                        />
                        <CInput
                          prepend="RM"
                          label="Price In Local Currency"
                          horizontal
                          v-model="obj.purchase.vehiclePriceLocalCurrency"
                        />
                        <CInput
                          prepend="RM"
                          label="Body Price"
                          horizontal
                          v-model="obj.purchase.bodyPriceLocalCurrency"
                        />
                        <!-- <CInput
                          label="AP Amount"
                          horizontal
                          v-model="obj.purchase.apAmount"
                        /> -->
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Import
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Ship Name"
                          horizontal
                          v-model="obj.import.shipName"
                        />
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Estimated Departure Date
                          </CCol>
                          <CCol sm="9">
                            <input
                              type="datetime-local"
                              :value="computeEstimateDateOfDeparture"
                              @change="setEstimateDateOfDeparture"
                              class="mr-2"
                            />
                          </CCol>
                        </CRow>
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Estimated Arrival Date
                          </CCol>
                          <CCol sm="9">
                            <input
                              type="datetime-local"
                              :value="computeEstimateDateOfArrival"
                              @change="setEstimateDateOfArrival"
                              class="mr-2"
                            />
                          </CCol>
                        </CRow>
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            BOL Date
                          </CCol>
                          <CCol sm="9">
                            <input
                              type="datetime-local"
                              :value="computeDateOfBillOfLading"
                              @change="setDateOfBillOfLading"
                              class="mr-2"
                            />
                          </CCol>
                        </CRow>
                        <!-- <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Bill Of Landing (BOL) Documents
                          </CCol>
                          <CCol sm="9"> 
                            <CImg
                              v-for="item in obj.vehicle.vehiclePhotoList"
                              :key="item.id"
                              :src="getImageUrl(item)"
                              class="mb-2"
                              thumbnail
                              @click="onImageClick(item)"
                            ></CImg>
                          </CCol>
                        </CRow> -->

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            BOL Documents
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedBolDocumentList"
                              :fields="bolDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="removeBolDocumentConfirmation(item)"
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument @uploaded="bol_uploaded" />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-chart-pie" /> Clearance
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Aprroved Permit No"
                          horizontal
                          v-model="obj.clearance.approvedPermitNo"
                        />
                        <CInput
                          label="K8 Document No"
                          horizontal
                          v-model="obj.clearance.k8DocumentNo"
                        />
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            K8 Documents
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedK8DocumentList"
                              :fields="k8DocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="removeK8DocumentConfirmation(item)"
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument @uploaded="k8_uploaded" />
                          </CCol>
                        </CRow>
                        <CInput
                          label="K1 Document No"
                          horizontal
                          v-model="obj.clearance.k1DocumentNo"
                        />

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            K1 Documents
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedK1DocumentList"
                              :fields="k1DocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="removeK1DocumentConfirmation(item)"
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument @uploaded="k1_uploaded" />
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-chart-pie" /> Sale
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Salesman Name"
                          horizontal
                          v-model="obj.sale.salesmanName"
                        />
                        <CInput
                          label="Customer"
                          horizontal
                          readonly
                          v-model="selectedCustomer.name"
                        >
                          <template #append>
                            <CButton
                              color="primary"
                              @click="onSearchCustomer()"
                            >
                              Search
                            </CButton>
                          </template>
                        </CInput>

                        <CInput
                          label="Sale Amount"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.saleAmount"
                        />
                        <CInput
                          label="Deposit Amount"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.depositAmount"
                        />
                        <CInput
                          label="Trade In Amount"
                          prepend="RM"
                          horizontal
                          description="Use for Disbursement Invoice Full"
                          v-model="obj.sale.tradeInAmount"
                        />
                        <CInput
                          label="EOE Amount"
                          prepend="RM"
                          horizontal
                          description="Use for Disbursement Invoice EOE"
                          v-model="obj.sale.eoeAmount"
                        />
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Is Bank Loan?
                          </CCol>
                          <CCol sm="9">
                            <CSwitch
                              class="mr-1"
                              color="primary"
                              :checked.sync="obj.sale.isUseLoan"
                              label-on="YES"
                              label-off="NO"
                            >
                            </CSwitch>
                          </CCol>
                        </CRow>
                        <!-- <CInput
                          label="Bank Name"
                          horizontal
                          v-model="obj.sale.loan.bankName"
                        /> -->
                        <CInput
                          label="Bank"
                          horizontal
                          readonly
                          v-model="selectedBank.name"
                          v-if="obj.sale.isUseLoan"
                        >
                          <template #append>
                            <CButton color="primary" @click="onSearchBank()">
                              Search
                            </CButton>
                          </template>
                        </CInput>

                        <CInput
                          label="Bank Deposit"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.loan.depositAmount"
                          v-if="obj.sale.isUseLoan"
                        />
                        <CInput
                          label="Requested Loan"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.loan.requestedLoanAmount"
                          v-if="obj.sale.isUseLoan"
                        />
                        <CInput
                          label="Loan Tenure"
                          horizontal
                          v-model="obj.sale.loan.loanTenure"
                          v-if="obj.sale.isUseLoan"
                        />
                        <CInput
                          label="Bank Unpaid Balance"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.loan.unpaidBalanceAmount"
                          v-if="obj.sale.isUseLoan"
                        />
                        <CInput
                          label="Approved Loan Amount"
                          prepend="RM"
                          horizontal
                          v-model="obj.sale.loan.approvedLoanAmount"
                          v-if="obj.sale.isUseLoan"
                        />
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Letter Of Undertaking (LOU)
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedLouDocumentList"
                              :fields="louDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="removeLouDocumentConfirmation(item)"
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument @uploaded="lou_uploaded" />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Pricing
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Recommended Sale Price"
                          prepend="RM"
                          horizontal
                          v-model="obj.pricing.recommendedSalePrice"
                        />
                        <CInput
                          label="Minimum Sale Price"
                          prepend="RM"
                          horizontal
                          v-model="obj.pricing.minimumSalePrice"
                        />
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>
                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Arrival Checklist
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Items
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardBody>
                                <CDataTable
                                  :items="computedArrivalChecklistList"
                                  :fields="arrivalChecklistFieldList"
                                  striped
                                  caption="Striped Table"
                                >
                                  <template #remove="{ item }">
                                    <td class="py-2">
                                      <CButton
                                        size="sm"
                                        color="info"
                                        class="ml-1"
                                        @click="onEditArrivalItem(item)"
                                      >
                                        Edit
                                      </CButton>
                                      <CButton
                                        size="sm"
                                        color="danger"
                                        class="ml-1"
                                        @click="
                                          showDeleteArrivalItemConfirmation(
                                            item
                                          )
                                        "
                                      >
                                        Delete
                                      </CButton>
                                    </td>
                                  </template>
                                </CDataTable></CCardBody
                              >
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addArrivalItemPopup()"
                                >
                                  Add
                                </CButton></CCardFooter
                              >
                            </CCard>
                          </CCol>
                        </CRow>
                        <!-- <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardHeader
                                ><strong> Add New Item</strong></CCardHeader
                              >
                              <CCardBody>
                                <CInput
                                  label="Item Name"
                                  horizontal
                                  v-model="newCheckListItem.name"
                                />
                                <CRow form class="form-group">
                                  <CCol
                                    tag="label"
                                    sm="3"
                                    class="col-form-label"
                                  >
                                    Is Available?
                                  </CCol>
                                  <CCol sm="9">
                                    <CSwitch
                                      class="mr-1"
                                      color="primary"
                                      :checked.sync="
                                        newCheckListItem.isAvailable
                                      "
                                      label-on="YES"
                                      label-off="NO"
                                    >
                                    </CSwitch>
                                  </CCol>
                                </CRow>

                                <CInput
                                  label="Remarks"
                                  horizontal
                                  v-model="newCheckListItem.remarks"
                                />
                              </CCardBody>
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addNewChecklistItem()"
                                >
                                  Add
                                </CButton>
                              </CCardFooter>
                            </CCard>
                          </CCol>
                        </CRow> -->
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Registration
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="RAC No"
                          horizontal
                          v-model="obj.registration.racNo"
                        />
                        <CInput
                          label="Vehicle Registration No"
                          horizontal
                          v-model="obj.registration.vehicleRegistrationNumber"
                          description="Vehicle Registration Certificate a.k.a Geran Kereta"
                        />

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Vehicle Registration Date
                          </CCol>
                          <CCol sm="9">
                            <input
                              type="datetime-local"
                              :value="computeVehicleRegistrationDate"
                              @change="setVehicleRegistrationDate"
                              class="mr-2"
                            />
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Vehicle Registration Cert.
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedJpjGeranDocumentList"
                              :fields="jpjGeranDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="
                                      removeJpjGeranDocumentConfirmation(item)
                                    "
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument
                              @uploaded="jpjGeran_uploaded"
                            />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            JPJ E-Hakmilik
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedJpjHakMilikDocumentList"
                              :fields="jpjHakMilikDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="
                                      removeJpjHakMilikDocumentConfirmation(
                                        item
                                      )
                                    "
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument
                              @uploaded="jpjHakMilik_uploaded"
                            />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            JPJ E-Daftar
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedJpjDaftarDocumentList"
                              :fields="jpjDaftarDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="
                                      removeJpjDaftarDocumentConfirmation(item)
                                    "
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument
                              @uploaded="jpjDaftar_uploaded"
                            />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Puspakom B2 Slip
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedPuspakomB2SlipDocumentList"
                              :fields="puspakomB2SlipDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="
                                      removePuspakomB2SlipDocumentConfirmation(
                                        item
                                      )
                                    "
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument
                              @uploaded="puspakomB2Slip_uploaded"
                            />
                            <!-- <CImg :src="uploadedImage" class="mb-2" thumbnail="true" /> -->
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Puspakom B7 Slip
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="computedPuspakomB7SlipDocumentList"
                              :fields="puspakomB7SlipDocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="item.documentUrl"
                                    >{{ item.documentName }}</CLink
                                  >
                                </td>
                              </template>
                              <template #remove="{ item }">
                                <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="
                                      removePuspakomB7SlipDocumentConfirmation(
                                        item
                                      )
                                    "
                                  >
                                    Remove
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <WidgetsUploadDocument
                              @uploaded="puspakomB7Slip_uploaded"
                            />
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Expenses
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Svc EzCare Amount"
                          prepend="RM"
                          v-model="obj.expense.serviceEzCareCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Interior Amount"
                          prepend="RM"
                          v-model="obj.expense.interiorCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Paint Amount"
                          prepend="RM"
                          v-model="obj.expense.paintCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Tyre Amount"
                          prepend="RM"
                          v-model="obj.expense.tyreCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Rental Amount"
                          prepend="RM"
                          v-model="obj.expense.rentalCostAmount"
                          horizontal
                        />
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Other Expenses
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardBody>
                                <CDataTable
                                  :items="computedExpensesList"
                                  :fields="expensesFieldList"
                                  striped
                                  caption="Striped Table"
                                >
                                  <template #remove="{ item }">
                                    <td class="py-2">
                                      <CButton
                                        size="sm"
                                        color="info"
                                        class="ml-1"
                                        @click="onEditExpenseItem(item)"
                                      >
                                        Edit
                                      </CButton>
                                      <CButton
                                        size="sm"
                                        color="danger"
                                        class="ml-1"
                                        @click="
                                          showDeleteExpenseItemConfirmation(
                                            item
                                          )
                                        "
                                      >
                                        Delete
                                      </CButton>
                                    </td>
                                    <!-- <td>
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="removeExpensesItem(item)"
                                  >
                                    Remove
                                  </CButton>
                                </td> -->
                                  </template>
                                </CDataTable></CCardBody
                              >
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addExpenseItemPopup()"
                                >
                                  Add
                                </CButton></CCardFooter
                              >
                            </CCard>
                          </CCol>
                        </CRow>
                        <!-- <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardHeader
                                ><strong> Add New Item</strong></CCardHeader
                              >
                              <CCardBody>
                                <CInput
                                  label="Item Name"
                                  horizontal
                                  v-model="newExpenseItem.name"
                                />
                                <CInput
                                  label="Amount"
                                  horizontal
                                  v-model="newExpenseItem.amount"
                                />

                                <CInput
                                  label="Remarks"
                                  horizontal
                                  v-model="newExpenseItem.remarks"
                                />
                              </CCardBody>
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addNewExpenseItem()"
                                >
                                  Add
                                </CButton>
                              </CCardFooter>
                            </CCard>
                          </CCol>
                        </CRow> -->
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cil-basket" /> Administrative Cost
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Duty Tax"
                          prepend="RM"
                          v-model="obj.adminitrativeCost.dutyCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Ap Amount"
                          prepend="RM"
                          v-model="obj.adminitrativeCost.apCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Forwarding Amount"
                          prepend="RM"
                          v-model="obj.adminitrativeCost.forwardingCostAmount"
                          horizontal
                        />
                        <CInput
                          label="Int FS Amount"
                          prepend="RM"
                          v-model="obj.adminitrativeCost.intFsCostAmount"
                          horizontal
                        />
                        <CInput
                          prepend="RM"
                          label="Insurance"
                          v-model="obj.adminitrativeCost.insuranceAmount"
                          horizontal
                        />
                        <CInput
                          prepend="RM"
                          label="Puspakom, Reg, R.Tax"
                          v-model="obj.adminitrativeCost.puspakomRegRoadTax"
                          horizontal
                        />

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Other Admin Cost
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardBody>
                                <CDataTable
                                  :items="computedAdministrativeCostList"
                                  :fields="administrativeCostFieldList"
                                  striped
                                  caption="Striped Table"
                                >
                                  <template #remove="{ item }">
                                    <td class="py-2">
                                      <CButton
                                        size="sm"
                                        color="info"
                                        class="ml-1"
                                        @click="onEditAdminItem(item)"
                                      >
                                        Edit
                                      </CButton>
                                      <CButton
                                        size="sm"
                                        color="danger"
                                        class="ml-1"
                                        @click="
                                          showDeleteAdminItemConfirmation(item)
                                        "
                                      >
                                        Delete
                                      </CButton>
                                    </td>
                                  </template>
                                </CDataTable></CCardBody
                              >
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addAdminItemPopup()"
                                >
                                  Add
                                </CButton></CCardFooter
                              >
                            </CCard>
                          </CCol>
                        </CRow>
                        <!-- <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                          </CCol>
                          <CCol sm="9">
                            <CCard>
                              <CCardHeader
                                ><strong> Add New Item</strong></CCardHeader
                              >
                              <CCardBody>
                                <CInput
                                  label="Item Name"
                                  horizontal
                                  v-model="newAdministrativeCostItem.name"
                                />
                                <CInput
                                  label="Amount"
                                  horizontal
                                  v-model="newAdministrativeCostItem.amount"
                                />

                                <CInput
                                  label="Remarks"
                                  horizontal
                                  v-model="newAdministrativeCostItem.remarks"
                                />
                              </CCardBody>
                              <CCardFooter>
                                <CButton
                                  color="primary"
                                  @click="addNewAdministrativeCostItem()"
                                >
                                  Add
                                </CButton>
                              </CCardFooter>
                            </CCard>
                          </CCol>
                        </CRow> -->
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>

                <CTab v-if="!isNewApplication()">
                  <template slot="title">
                    <CIcon name="cilCalculator" /> Disbursement
                  </template>
                  <CCard>
                    <CCardBody>
                      <CForm>
                        <CInput
                          label="Sub Company"
                          description="Company that used for AP"
                          horizontal
                          readonly
                          v-model="selectedApSubCompany.name"
                        >
                          <template #append>
                            <CButton
                              color="primary"
                              @click="onSearchApSubCompany()"
                            >
                              Search
                            </CButton>
                          </template>
                        </CInput>
                        <CInput
                          label="Bank Account"
                          horizontal
                          readonly
                          v-model="selectedApBankAccount.name"
                        >
                          <template #append>
                            <CButton
                              color="primary"
                              @click="onSearchApBankAccount()"
                            >
                              Search
                            </CButton>
                          </template>
                        </CInput>

                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            Disbursment Letters
                          </CCol>
                          <CCol sm="9">
                            <CRow>
                              <CCol>
                                <CButton
                                  size="sm"
                                  color="info"
                                  class="ml-1"
                                  @click="onOpenDisburstmentLetter()"
                                >
                                  Open
                                </CButton>
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CTab>
              </CTabs>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary" @click="submit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >
              <!-- <CButton
                class="ml-1"
                color="danger"
                :disabled="!isDirty"
                @click="reset"
              >
                Reset
              </CButton> -->
              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
          <!-- Modal for maximizing image -->
          <CModal
            title="Maximized Image"
            :show.sync="isMaximizeImageModal"
            size="lg"
            @close="isMaximizeImageModal = false"
          >
            <CImg :src="currentImageUrl" class="maximized-image" />
          </CModal>
        </CCol>
        <CCol sm="3">
          <CCard>
            <CCardHeader> <strong> Remarks </strong> </CCardHeader>
            <CCardBody>
              <CTextarea placeholder="Content..." rows="5" v-model="remark" />
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="submitNewMessage"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >
            </CCardFooter>
          </CCard>
          <CCard>
            <CCardHeader> <strong> Remark </strong> History </CCardHeader>
            <CCardBody>
              <CListGroup>
                <CListGroupItem
                  v-for="info in obj.remarksList"
                  :key="info.id"
                  class="flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <strong>{{ getProfileFullName(info) }}</strong>
                    <small>{{ getDisplayDateTime(info.dateTime) }}</small>
                  </div>
                  <p class="mb-1">
                    {{ info.description }}
                  </p>
                </CListGroupItem>
              </CListGroup>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div>
      <CModal title="Search for Brand" :show.sync="brandSearchPopup" size="xl">
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="brandList"
                  :fields="brandFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onBrandSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal title="Search for Model" :show.sync="modelSearchPopup" size="xl">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardBody>
                <CDataTable
                  :items="modelList"
                  :fields="modelFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onModelSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal
        title="Search for Supplier"
        :show.sync="supplierSearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="supplierList"
                  :fields="supplierFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onSupplierSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal
        title="Search for Customer"
        :show.sync="customerSearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="customerList"
                  :fields="customerFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onCustomerSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal title="Search for Bank" :show.sync="bankSearchPopup" size="xl">
        <CRow>
          <CCol sm="12">
            <CCard>
              <!-- <CCardHeader> <strong> Campaign </strong> List </CCardHeader> -->
              <CCardBody>
                <CDataTable
                  :items="bankList"
                  :fields="bankFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onBankSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal
        title="Search for Company"
        :show.sync="subCompanySearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardBody>
                <CDataTable
                  :items="subCompanyList"
                  :fields="subApSubCompanyFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onSubCompanySelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal
        title="Search for Bank Account"
        :show.sync="bankAccountSearchPopup"
        size="xl"
      >
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardBody>
                <CDataTable
                  :items="bankAccountList"
                  :fields="bankAccountFieldList"
                  column-filter
                  sorter
                  :loading="loading"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="onBankAccountSelected(item, index)"
                      >
                        Select
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CModal>
    </div>

    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removePuspakomB7SlipDocumentWarningModal"
        @update:show="onRemovePuspakomB7SlipDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removePuspakomB2SlipDocumentWarningModal"
        @update:show="onRemovePuspakomB2SlipDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeJpjDaftarDocumentWarningModal"
        @update:show="onRemoveJpjDaftarDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeJpjHakMilikDocumentWarningModal"
        @update:show="onRemoveJpjHakMilikDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>

    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeJpjGeranDocumentWarningModal"
        @update:show="onRemoveJpjGeranDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>

    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeBolDocumentWarningModal"
        @update:show="onRemoveBolDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeK8DocumentWarningModal"
        @update:show="onRemoveK8DocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeK1DocumentWarningModal"
        @update:show="onRemoveK1DocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>

    <div>
      <CModal
        title="Confirm Remove Document"
        color="warning"
        :show.sync="removeLouDocumentWarningModal"
        @update:show="onRemoveLouDocumentConfirmation"
      >
        Are you sure you want to delete?
      </CModal>
    </div>

    <div>
      <CModal
        title="Add/Edit Expense Item"
        :show.sync="addNewExpenseItemPopupState"
        size="xl"
        @update:show="onAddNewExpenseItemConfirmation"
      >
        <CForm>
          <CInput label="Name" v-model="newExpenseItem.name" horizontal />

          <CInput
            label="Amount"
            prepend="RM"
            v-model="newExpenseItem.amount"
            horizontal
          />
          <CInput label="Remarks" v-model="newExpenseItem.remarks" horizontal />
        </CForm>
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Delete"
        color="warning"
        :show.sync="warningDeleteExpenseItemModal"
        @update:show="onDeleteExpenseItemConfirmation"
      >
        Are you sure you want to delete this
        {{ itemExpenseItemToDelete.name }} ?
      </CModal>
    </div>

    <div>
      <CModal
        title="Add/Edit Admin Item"
        :show.sync="addNewAdminItemPopupState"
        size="xl"
        @update:show="onAddNewAdminItemConfirmation"
      >
        <CForm>
          <CInput label="Name" v-model="newAdminItem.name" horizontal />

          <CInput
            label="Amount"
            prepend="RM"
            v-model="newAdminItem.amount"
            horizontal
          />
          <CInput label="Remarks" v-model="newAdminItem.remarks" horizontal />
        </CForm>
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Delete"
        color="warning"
        :show.sync="warningDeleteAdminItemModal"
        @update:show="onDeleteAdminItemConfirmation"
      >
        Are you sure you want to delete this
        {{ itemAdminItemToDelete.name }} ?
      </CModal>
    </div>

    <div>
      <CModal
        title="Add/Edit Arrival Item"
        :show.sync="addNewArrivalItemPopupState"
        size="xl"
        @update:show="onAddNewArrivalItemConfirmation"
      >
        <CForm>
          <CInput label="Name" v-model="newArrivalItem.name" horizontal />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              Is Available?
            </CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="newArrivalItem.isAvailable"
                label-on="YES"
                label-off="NO"
              >
              </CSwitch>
            </CCol>
          </CRow>

          <!-- <CInput
            label="Amount"
            prepend="RM"
            v-model="newArrivalItem.amount"
            horizontal
          /> -->
          <CInput label="Remarks" v-model="newArrivalItem.remarks" horizontal />
        </CForm>
      </CModal>
    </div>
    <div>
      <CModal
        title="Confirm Delete"
        color="warning"
        :show.sync="warningDeleteArrivalItemModal"
        @update:show="onDeleteArrivalItemConfirmation"
      >
        Are you sure you want to delete this
        {{ itemArrivalItemToDelete.name }} ?
      </CModal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StockApi from "../../lib/stockApi";
import StockStatusApi from "../../lib/stockStatusApi";
import VehicleTypeApi from "../../lib/vehicleTypeApi";
import BrandApi from "../../lib/brandApi";
import ModelApi from "../../lib/modelApi";
import SupplierApi from "../../lib/supplierApi";
import CustomerApi from "../../lib/customerApi";
import BankApi from "../../lib/bankApi";
import SubCompanyApi from "../../lib/subCompanyApi";
import BankAccountApi from "../../lib/bankAccountApi";
import WidgetsUploadImage from "../widgets/WidgetsUploadImage";
import WidgetsUploadDocument from "../widgets/WidgetsUploadDocument";

import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment";

const stockStatusHistoryFieldList = [
  {
    key: "status_name",
    _style: "min-width:100px;",
    label: "Name",
  },
  {
    key: "date_time",
    label: "Date",
  },
  {
    key: "profile_name",
    label: "Update By",
  },
];

const brandList = [];
const brandFieldList = [
  { key: "name" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const modelList = [];
const modelFieldList = [
  { key: "name" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const supplierList = [];
const supplierFieldList = [
  { key: "name" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const customerList = [];
const customerFieldList = [
  { key: "name" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const bankList = [];
const bankFieldList = [
  { key: "name" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const puspakomB7SlipDocumentList = [];
const puspakomB7SlipDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const puspakomB2SlipDocumentList = [];
const puspakomB2SlipDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const jpjDaftarDocumentList = [];
const jpjDaftarDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const jpjHakMilikDocumentList = [];
const jpjHakMilikDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const jpjGeranDocumentList = [];
const jpjGeranDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const bolDocumentList = [];
const bolDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const k8DocumentList = [];
const k8DocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const k1DocumentList = [];
const k1DocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const louDocumentList = [];
const louDocumentFieldList = [
  {
    key: "document_link",
    label: "Document",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const administrativeCostList = [];
const administrativeCostFieldList = [
  {
    key: "name",
    sorter: false,
    filter: false,
  },
  {
    key: "amount",
    sorter: false,
    filter: false,
  },
  {
    key: "remarks",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];
const expensesList = [];
const expensesFieldList = [
  {
    key: "name",
    sorter: false,
    filter: false,
  },
  {
    key: "amount",
    sorter: false,
    filter: false,
  },
  {
    key: "remarks",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

const arrivalChecklistList = [];
const arrivalChecklistFieldList = [
  {
    key: "name",
    sorter: false,
    filter: false,
  },
  {
    key: "isAvailable",
    sorter: false,
    filter: false,
  },
  {
    key: "remarks",
    sorter: false,
    filter: false,
  },
  {
    key: "remove",
    label: "",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Stock",
  components: {
    WidgetsUploadImage,
    WidgetsUploadDocument,
    WidgetsUploadDocument,
  },

  data: () => {
    return {
      warningDeleteArrivalItemModal: false,
      itemArrivalItemToDelete: {},
      addNewArrivalItemPopupState: false,
      newArrivalItem: {
        id: null,
        name: "",
        isAvailable: false,
        remarks: "",
      },

      warningDeleteAdminItemModal: false,
      itemAdminItemToDelete: {},
      addNewAdminItemPopupState: false,
      newAdminItem: {
        id: null,
        name: "",
        amount: 0,
        remarks: "",
      },

      warningDeleteExpenseItemModal: false,
      itemExpenseItemToDelete: {},
      addNewExpenseItemPopupState: false,
      newExpenseItem: {
        id: null,
        name: "",
        amount: 0,
        remarks: "",
      },

      arrivalStates: [],
      selectedArrivalState: null,

      isMaximizeImageModal: false, // State for modal visibility
      currentImageUrl: "", // State to store the URL of the image to be maximized

      newAdministrativeCostItem: {
        name: "",
        amount: 0,
        remarks: "",
      },
      administrativeCostToDelete: null,
      removeAdministrativeCostWarningModal: false,

      administrativeCostList: administrativeCostList.map((item, id) => {
        return { ...item, id };
      }),
      administrativeCostFieldList,

      //
      newExpenseItem: {
        id: null,
        name: "",
        amount: 0,
        remarks: "",
      },
      expensesToDelete: null,
      removeExpensesWarningModal: false,

      expensesList: expensesList.map((item, id) => {
        return { ...item, id };
      }),
      expensesFieldList,

      //

      newCheckListItem: {
        name: "",
        isAvailable: false,
        remarks: "",
      },
      arrivalChecklistToDelete: null,
      removeArrivalChecklistWarningModal: false,

      arrivalChecklistList: arrivalChecklistList.map((item, id) => {
        return { ...item, id };
      }),
      arrivalChecklistFieldList,

      //

      louDocumentToDelete: null,
      removeLouDocumentWarningModal: false,

      louDocumentList: louDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      louDocumentFieldList,

      //
      k8DocumentToDelete: null,
      removeK8DocumentWarningModal: false,

      k8DocumentList: k8DocumentList.map((item, id) => {
        return { ...item, id };
      }),
      k8DocumentFieldList,
      //

      //
      k1DocumentToDelete: null,
      removeK1DocumentWarningModal: false,

      k1DocumentList: k1DocumentList.map((item, id) => {
        return { ...item, id };
      }),
      k1DocumentFieldList,
      //

      bolDocumentToDelete: null,
      removeBolDocumentWarningModal: false,

      bolDocumentList: bolDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      bolDocumentFieldList,

      //
      puspakomB7SlipDocumentToDelete: null,
      removePuspakomB7SlipDocumentWarningModal: false,

      puspakomB7SlipDocumentList: puspakomB7SlipDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      puspakomB7SlipDocumentFieldList,
      //
      puspakomB2SlipDocumentToDelete: null,
      removePuspakomB2SlipDocumentWarningModal: false,

      puspakomB2SlipDocumentList: puspakomB2SlipDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      puspakomB2SlipDocumentFieldList,

      //
      jpjDaftarDocumentToDelete: null,
      removeJpjDaftarDocumentWarningModal: false,

      jpjDaftarDocumentList: jpjDaftarDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      jpjDaftarDocumentFieldList,

      //
      jpjHakMilikDocumentToDelete: null,
      removeJpjHakMilikDocumentWarningModal: false,

      jpjHakMilikDocumentList: jpjHakMilikDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      jpjHakMilikDocumentFieldList,

      //
      jpjGeranDocumentToDelete: null,
      removeJpjGeranDocumentWarningModal: false,

      jpjGeranDocumentList: jpjGeranDocumentList.map((item, id) => {
        return { ...item, id };
      }),
      jpjGeranDocumentFieldList,

      //Model
      modelApi: new ModelApi(),
      modelSearchPopup: false,
      modelList: modelList.map((item, id) => {
        return { ...item, id };
      }),
      modelFieldList,
      selectedModel: {
        id: null,
        name: "",
      },
      //Model

      //Brand
      brandApi: new BrandApi(),
      brandSearchPopup: false,
      brandList: brandList.map((item, id) => {
        return { ...item, id };
      }),
      brandFieldList,
      selectedBrand: {
        id: null,
        name: "",
      },
      //Brand

      //Sub Company
      subCompanyApi: new SubCompanyApi(),
      subCompanySearchPopup: false,
      subCompanyList: [],
      subApSubCompanyFieldList: [
        { key: "name" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      selectedApSubCompany: {
        id: null,
        name: "",
      },
      //Brand

      //Bank Account
      bankAccountApi: new BankAccountApi(),
      bankAccountSearchPopup: false,
      bankAccountList: [],
      bankAccountFieldList: [
        { key: "name" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      selectedApBankAccount: {
        id: null,
        name: "",
      },

      //Bank Account
      //Supplier
      supplierApi: new SupplierApi(),
      supplierSearchPopup: false,
      supplierList: supplierList.map((item, id) => {
        return { ...item, id };
      }),
      supplierFieldList,
      selectedSupplier: {
        id: null,
        name: "",
      },

      customerApi: new CustomerApi(),
      customerSearchPopup: false,
      customerList: customerList.map((item, id) => {
        return { ...item, id };
      }),
      customerFieldList,
      selectedCustomer: {
        id: null,
        name: "",
      },

      bankApi: new BankApi(),
      bankSearchPopup: false,
      bankList: bankList.map((item, id) => {
        return { ...item, id };
      }),
      bankFieldList,
      selectedBank: {
        id: null,
        name: "",
      },

      //Brand

      stockStatuses: [],
      selectedStockStatusId: null,

      vehicleTypes: [],
      selectedVehicleTypeId: null,

      infoList: [],
      api: new StockApi(),
      stockStatusApi: new StockStatusApi(),
      vehicleTypeApi: new VehicleTypeApi(),
      obj: {
        stockNo: "",
        vehicle: {
          chasisNo: "",
        },
        purchase: {
          supplier: {
            name: "",
          },
        },
        import: {
          shipName: "",
        },
        clearance: {
          approvedPermitNo: "",
        },
        sale: {
          customer: {
            name: "",
          },
          depositAmount: 0,
          loan: {
            depositAmount: 0,
            bank: {
              name: "",
            },
          },
        },
        pricing: {
          recommendedSalePrice: 0,
        },
        registration: {
          racNo: "",
        },
      },
      // categoryList: categoryList.map((item, index) => {
      //   return { ...item, index };
      // }),
      stockStatusHistoryFieldList,

      loading: false,
      submitted: false,
      remark: "",
      estimateDateOfDeparture: Date(),
      estimateDateOfArrival: Date(),
      dateOfBillOfLading: Date(),
      vehicleRegistrationDate: Date(),
      systemRegistrationDate: Date(),
    };
  },
  mounted() {
    var self = this;
    self.fetchArrivalStates();
    self.refreshStockStatus();
    self.refreshVehicleType();
    self.resetObj();
  },
  validations: {
    obj: {
      stockNo: {
        required,
        minLength: minLength(2),
      },
    },
  },
  computed: {
    sortedVehiclePhotos() {
      // Sort the images by their Position property
      return this.obj.vehicle.vehiclePhotoList.sort(
        (a, b) => a.position - b.position
      );
    },
    computedAdministrativeCostList() {
      return this.administrativeCostList.map((item) => {
        return {
          ...item,
        };
      });
    },
    computedExpensesList() {
      return this.expensesList.map((item) => {
        return {
          ...item,
        };
      });
    },
    computedArrivalChecklistList() {
      return this.arrivalChecklistList.map((item) => {
        return {
          ...item,
        };
      });
    },
    computedLouDocumentList() {
      return this.louDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedK8DocumentList() {
      return this.k8DocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedK1DocumentList() {
      return this.k1DocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },

    computedPuspakomB7SlipDocumentList() {
      return this.puspakomB7SlipDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedPuspakomB2SlipDocumentList() {
      return this.puspakomB2SlipDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedJpjDaftarDocumentList() {
      return this.jpjDaftarDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedJpjHakMilikDocumentList() {
      return this.jpjHakMilikDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computedJpjGeranDocumentList() {
      return this.jpjGeranDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },

    computedBolDocumentList() {
      return this.bolDocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    computeEstimateDateOfDeparture() {
      return moment(this.estimateDateOfDeparture).format("YYYY-MM-DDTHH:mm");
    },
    computeEstimateDateOfArrival() {
      return moment(this.estimateDateOfArrival).format("YYYY-MM-DDTHH:mm");
    },
    computeDateOfBillOfLading() {
      return moment(this.dateOfBillOfLading).format("YYYY-MM-DDTHH:mm");
    },

    computeVehicleRegistrationDate() {
      return moment(this.vehicleRegistrationDate).format("YYYY-MM-DDTHH:mm");
    },

    computeSystemRegistrationDate() {
      return moment(this.systemRegistrationDate).format("YYYY-MM-DDTHH:mm");
    },

    vehicleYear() {
      var year = [];
      year.push("");
      for (let i = 2000; i < 2050; i++) {
        year.push(i.toString());
      }
      return year;
    },
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },
  methods: {
    onEditExpenseItem(item) {
      this.newExpenseItem = item;
      this.addNewExpenseItemPopupState = true;
    },
    onDeleteExpenseItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .removeExpenseItem(this.itemExpenseItemToDelete.id)
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemExpenseItemToDelete = {};
    },

    showDeleteExpenseItemConfirmation(item) {
      var self = this;
      self.itemExpenseItemToDelete = item;
      self.warningDeleteExpenseItemModal = true;
    },

    onAddNewExpenseItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        console.log(self.newExpenseItem);

        if (self.newExpenseItem.id) {
          this.api
            .updateExpenseItem(self.obj.id, self.newExpenseItem)
            .then((response) => {
              self.toast("Success", "Expense Item Updated", "success");

              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        } else {
          console.log(self.newExpenseItem);
          this.api
            .addExpenseItem(self.obj.id, self.newExpenseItem)
            .then((response) => {
              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        }
      }
    },

    addExpenseItemPopup() {
      this.resetNewExpenseItem();
      this.addNewExpenseItemPopupState = true;
    },
    resetNewExpenseItem() {
      this.newExpenseItem.id = null;
      this.newExpenseItem.name = "";
      this.newExpenseItem.amount = 0;
      this.newExpenseItem.remarks = "";
    },

    onEditAdminItem(item) {
      this.newAdminItem = item;
      this.addNewAdminItemPopupState = true;
    },
    onDeleteAdminItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .removeAdministrativeCostItem(this.itemAdminItemToDelete.id)
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemAdminItemToDelete = {};
    },

    showDeleteAdminItemConfirmation(item) {
      var self = this;
      self.itemAdminItemToDelete = item;
      self.warningDeleteAdminItemModal = true;
    },

    onAddNewAdminItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        console.log(self.newAdminItem);

        if (self.newAdminItem.id) {
          this.api
            .updateAdministrativeCostItem(self.obj.id, self.newAdminItem)
            .then((response) => {
              self.toast("Success", "Admin Item Updated", "success");

              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        } else {
          console.log(self.newAdminItem);
          this.api
            .addAdministrativeCostItem(self.obj.id, self.newAdminItem)
            .then((response) => {
              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        }
      }
    },

    addAdminItemPopup() {
      this.resetNewAdminItem();
      this.addNewAdminItemPopupState = true;
    },
    resetNewAdminItem() {
      this.newAdminItem.id = null;
      this.newAdminItem.name = "";
      this.newAdminItem.amount = 0;
      this.newAdminItem.remarks = "";
    },


    //

    onEditArrivalItem(item) {
      this.newArrivalItem = item;
      this.addNewArrivalItemPopupState = true;
    },
    onDeleteArrivalItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .removeArrivalItem(this.itemArrivalItemToDelete.id)
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemArrivalItemToDelete = {};
    },

    showDeleteArrivalItemConfirmation(item) {
      var self = this;
      self.itemArrivalItemToDelete = item;
      self.warningDeleteArrivalItemModal = true;
    },

    onAddNewArrivalItemConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        console.log(self.newArrivalItem);

        if (self.newArrivalItem.id) {
          this.api
            .updateArrivalItem(self.obj.id, self.newArrivalItem)
            .then((response) => {
              self.toast("Success", "Arrival Item Updated", "success");

              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        } else {
          console.log(self.newArrivalItem);
          this.api
            .addArrivalItem(self.obj.id, self.newArrivalItem)
            .then((response) => {
              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        }
      }
    },

    addArrivalItemPopup() {
      this.resetNewArrivalItem();
      this.addNewArrivalItemPopupState = true;
    },
    resetNewArrivalItem() {
      this.newArrivalItem.id = null;
      this.newArrivalItem.name = "";
      this.newArrivalItem.isAvailable = false;
      this.newArrivalItem.remarks = "";
    },

    onOpenDisburstmentLetter() {
      var self = this;
      if (self.obj && self.obj.id) {
        const newUrl = self.$router.resolve({
          path: `/admins/Disburstment/${self.obj.id}`,
        }).href;
        window.open(newUrl, "_blank"); // Opens in a new tab
      } else {
        console.error("Error: obj.id is undefined or invalid.");
      }
    },
    fetchArrivalStates() {
      var self = this;
      self.api
        .getArrivaleState()
        .then((response) => {
          var obj = response.result;
          this.arrivalStates = obj.map((state) => ({
            value: state.id,
            label: state.name,
          }));

          console.log(this.arrivalStates);
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    openLink(url) {
      window.open(url, "_blank");
    },
    maximizeImage(item) {
      this.currentImageUrl = this.getImageUrl(item); // Set the current image URL
      this.isMaximizeImageModal = true; // Show the modal
    },
    moveImageUp(index) {
      if (index > 0) {
        // Swap positions of images
        const currentImage = this.obj.vehicle.vehiclePhotoList[index];
        const previousImage = this.obj.vehicle.vehiclePhotoList[index - 1];

        currentImage.position--;
        previousImage.position++;

        this.sortAndSaveImages();
      }
    },
    moveImageDown(index) {
      if (index < this.obj.vehicle.vehiclePhotoList.length - 1) {
        // Swap positions of images
        const currentImage = this.obj.vehicle.vehiclePhotoList[index];
        const nextImage = this.obj.vehicle.vehiclePhotoList[index + 1];

        currentImage.position++;
        nextImage.position--;

        this.sortAndSaveImages();
      }
    },
    sortAndSaveImages() {
      // Sort images based on Position
      this.obj.vehicle.vehiclePhotoList.sort((a, b) => a.position - b.position);

      // Update the server with the new image positions if needed
      // this.updateImagePositionsOnServer();
      // console.log(this.obj.vehicle.vehiclePhotoList);
      var self = this;
      self.api
        .updateImagePosition(self.obj)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    //
    isDefaultImage(item) {
      return (
        "/Documents/File/" + item.documentId ===
        this.obj.vehicle.defaultImageUrl
      );
    },
    deleteImage(imageId) {
      if (confirm("Are you sure you want to delete this image?")) {
        var self = this;
        self.api
          .deleteVehicleImage(imageId)
          .then((response) => {
            self.toast("Message", "Updated", "success");
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });

        // Remove the image from the list
        // this.obj.vehicle.vehiclePhotoList =
        //   this.obj.vehicle.vehiclePhotoList.filter(
        //     (image) => image.id !== imageId
        //   );

        // // If the deleted image was the default, reset the default image URL
        // if (
        //   this.obj.vehicle.ImageDefaultUrl === this.getImageUrl({ id: imageId })
        // ) {
        //   this.obj.vehicle.ImageDefaultUrl = ""; // Reset or choose a new default
        // }

        // Add logic to delete the image on the server side
      }
    },
    setDefaultImage(item) {
      // Set the selected image's URL as the default
      // console.log(item);
      var self = this;
      self.obj.vehicle.defaultImageUrl = "/Documents/File/" + item.documentId;
      self.api
        .updateDefaultImages(self.obj)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });

      // Add logic to update the default image on the server side
    },
    getProfileFullName(item) {
      // console.log(item);
      try {
        return item.profile.fullName;
      } catch (error) {
        return "Unknown";
      }
    },
    addNewAdministrativeCostItem() {
      var self = this;
      self.api
        .addAdministrativeCostItem(self.obj.id, self.newAdministrativeCostItem)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    removeAdministrativeCostItem(item) {
      // console.log(item);
      var self = this;
      self.api
        .removeAdministrativeCostItem(item.id)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    //
    addNewExpenseItem() {
      var self = this;
      self.api
        .addExpenseItem(self.obj.id, self.newExpenseItem)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    removeExpensesItem(item) {
      // console.log(item);
      var self = this;
      self.api
        .removeExpenseItem(item.id)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    //
    addNewChecklistItem() {
      var self = this;
      self.api
        .addArrivalCheckLisItem(self.obj.id, self.newCheckListItem)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    removeArrivalChecklistItem(item) {
      // console.log(item);
      var self = this;
      self.api
        .removeArrivalCheckLisItem(item.id)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    isNewApplication() {
      if (this.$route.params.id) return false;
      return true;
    },
    getCollectionDocumentName(item) {
      if (item.document == null) return "Unknown";
      if (item.document.fileName == null) return "Unknown";

      return item.document.fileName;
    },
    getCollectionDocumentUrl(item) {
      var self = this;
      return apiUrl + "documents/file/" + item.documentId;
    },

    removeLouDocumentConfirmation(item) {
      var self = this;
      self.louDocumentToDelete = item;
      self.removeLouDocumentWarningModal = true;
    },
    onRemoveLouDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeLouDocuments(
            self.louDocumentToDelete.loanId,
            self.louDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    removeK8DocumentConfirmation(item) {
      var self = this;
      self.k8DocumentToDelete = item;
      self.removeK8DocumentWarningModal = true;
    },
    onRemoveK8DocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeK8Documents(
            self.k8DocumentToDelete.clearanceId,
            self.k8DocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    removeK1DocumentConfirmation(item) {
      var self = this;
      self.k1DocumentToDelete = item;
      self.removeK1DocumentWarningModal = true;
    },
    onRemoveK1DocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeK1Documents(
            self.k1DocumentToDelete.clearanceId,
            self.k1DocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    removePuspakomB7SlipDocumentConfirmation(item) {
      var self = this;
      self.puspakomB7SlipDocumentToDelete = item;
      self.removePuspakomB7SlipDocumentWarningModal = true;
    },
    onRemovePuspakomB7SlipDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removePuspakomB7SlipDocuments(
            self.puspakomB7SlipDocumentToDelete.registrationId,
            self.puspakomB7SlipDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    removePuspakomB2SlipDocumentConfirmation(item) {
      var self = this;
      self.puspakomB2SlipDocumentToDelete = item;
      self.removePuspakomB2SlipDocumentWarningModal = true;
    },
    onRemovePuspakomB2SlipDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removePuspakomB2SlipDocuments(
            self.puspakomB2SlipDocumentToDelete.registrationId,
            self.puspakomB2SlipDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    removeJpjDaftarDocumentConfirmation(item) {
      var self = this;
      self.jpjDaftarDocumentToDelete = item;
      self.removeJpjDaftarDocumentWarningModal = true;
    },
    onRemoveJpjDaftarDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeJpjDaftarDocuments(
            self.jpjDaftarDocumentToDelete.registrationId,
            self.jpjDaftarDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    removeJpjHakMilikDocumentConfirmation(item) {
      var self = this;
      self.jpjHakMilikDocumentToDelete = item;
      self.removeJpjHakMilikDocumentWarningModal = true;
    },
    onRemoveJpjHakMilikDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeJpjHakMilikDocuments(
            self.jpjHakMilikDocumentToDelete.registrationId,
            self.jpjHakMilikDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    //
    removeJpjGeranDocumentConfirmation(item) {
      var self = this;
      self.jpjGeranDocumentToDelete = item;
      self.removeJpjGeranDocumentWarningModal = true;
    },
    onRemoveJpjGeranDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeJpjGeranDocuments(
            self.jpjGeranDocumentToDelete.registrationId,
            self.jpjGeranDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    //
    removeBolDocumentConfirmation(item) {
      var self = this;
      self.bolDocumentToDelete = item;
      self.removeBolDocumentWarningModal = true;
    },
    onRemoveBolDocumentConfirmation(status, evt, accept) {
      if (accept) {
        var self = this;
        self.api
          .removeBolDocuments(
            self.bolDocumentToDelete.importId,
            self.bolDocumentToDelete.documentId
          )
          .then((response) => {
            self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    setEstimateDateOfDeparture(e) {
      this.estimateDateOfDeparture = new Date(e.target.value).getTime();
    },
    setEstimateDateOfArrival(e) {
      this.estimateDateOfArrival = new Date(e.target.value).getTime();
    },
    setDateOfBillOfLading(e) {
      this.dateOfBillOfLading = new Date(e.target.value).getTime();
    },

    setVehicleRegistrationDate(e) {
      this.vehicleRegistrationDate = new Date(e.target.value).getTime();
    },

    setSystemRegistrationDate(e) {
      this.systemRegistrationDate = new Date(e.target.value).getTime();
    },

    onImageClick(item) {},
    getImageUrl(item) {
      var url = apiUrl + "Documents/File/" + item.documentId;
      // console.log(url);
      return url;
    },
    puspakomB7Slip_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updatePuspakomB7SlipDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    puspakomB2Slip_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updatePuspakomB2SlipDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          // self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    jpjDaftar_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateJpjDaftarDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //  self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    jpjHakMilik_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateJpjHakMilikDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //   self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    jpjGeran_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateJpjGeranDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //   self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    bol_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateBolDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          // self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    k8_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateK8Documents(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //    self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    k1_uploaded(data) {
      // console.log(data);
      var self = this;
      self.api
        .updateK1Documents(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //    self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    lou_uploaded(data) {
      var self = this;
      self.api
        .updateLouDocuments(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.refreshDocumentList();
          //   self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    media_uploaded(data) {
      var self = this;
      self.api
        .updateVehicleImages(self.obj.id, data.uploadedFiles)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //
    onVehicleTypeSelect() {
      this.obj.vehicle.vehicleTypeId = this.selectedVehicleTypeId;
      // console.log(this.obj.vehicle.vehicleTypeId);
    },
    //Model Start
    onSearchModel() {
      var self = this;
      self.refreshTableModel();
      self.modelSearchPopup = true;
    },
    onModelSelected(item) {
      var self = this;
      self.selectedModel = item;
      self.obj.vehicle.modelId = item.id.toString();

      self.modelSearchPopup = false;
    },
    refreshTableModel() {
      var self = this;
      self.loading = true;
      self.modelApi
        .getListByBrandId(self.selectedBrand.id)
        .then((response) => {
          self.modelList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Model End

    //Brand Start
    onSearchBrand() {
      var self = this;
      self.refreshTableBrand();
      self.brandSearchPopup = true;
    },
    onBrandSelected(item) {
      var self = this;
      self.selectedBrand = item;
      self.obj.vehicle.brandId = item.id.toString();

      self.brandSearchPopup = false;
    },
    refreshTableBrand() {
      var self = this;
      self.loading = true;
      self.brandApi
        .getList()
        .then((response) => {
          self.brandList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Brand End

    //AP Company
    onSearchApSubCompany() {
      var self = this;
      self.refreshTableSubCompany();
      self.subCompanySearchPopup = true;
    },
    onSubCompanySelected(item) {
      var self = this;
      self.selectedApSubCompany = item;
      if (self.obj.apCompany.subCompanyId != item.id.toString()) {
        self.obj.apCompany.subCompanyId = item.id.toString();
        self.obj.apCompany.bankAccountId = "";
        self.obj.apCompany.bankAccount = {};
        self.selectedApBankAccount = {};
      }
      self.subCompanySearchPopup = false;
    },
    refreshTableSubCompany() {
      var self = this;
      self.loading = true;
      self.subCompanyApi
        .getList()
        .then((response) => {
          self.subCompanyList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //AP Company

    //Bank Account Start
    onSearchApBankAccount() {
      var self = this;
      self.refreshTableBankAccount();
      self.bankAccountSearchPopup = true;
    },
    onBankAccountSelected(item) {
      var self = this;
      self.selectedApBankAccount = item;
      self.obj.apCompany.bankAccountId = item.id.toString();

      self.bankAccountSearchPopup = false;
    },
    refreshTableBankAccount() {
      var self = this;
      self.loading = true;
      self.bankAccountApi
        .getListBySubCompanyId(self.selectedApSubCompany.id)
        .then((response) => {
          self.bankAccountList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Bank Account End

    //Supplier Start
    onSearchSupplier() {
      var self = this;
      self.refreshTableSupplier();
      self.supplierSearchPopup = true;
    },
    onSupplierSelected(item) {
      var self = this;
      self.selectedSupplier = item;
      self.obj.purchase.supplierId = item.id.toString();

      self.supplierSearchPopup = false;
    },
    refreshTableSupplier() {
      var self = this;
      self.loading = true;
      self.supplierApi
        .getList()
        .then((response) => {
          self.supplierList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Supplier End

    //Customer Start
    onSearchCustomer() {
      var self = this;
      self.refreshTableCustomer();
      self.customerSearchPopup = true;
    },
    onCustomerSelected(item) {
      var self = this;
      self.selectedCustomer = item;
      self.obj.sale.customerId = item.id.toString();

      self.customerSearchPopup = false;
    },
    refreshTableCustomer() {
      var self = this;
      self.loading = true;
      self.customerApi
        .getListWithoutFilter()
        .then((response) => {
          self.customerList = response.result.items;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Customer End

    //Bank Start
    onSearchBank() {
      var self = this;
      self.refreshTableBank();
      self.bankSearchPopup = true;
    },
    onBankSelected(item) {
      var self = this;
      self.selectedBank = item;
      self.obj.sale.loan.bankId = item.id.toString();

      self.bankSearchPopup = false;
    },
    refreshTableBank() {
      var self = this;
      self.loading = true;
      self.bankApi
        .getList()
        .then((response) => {
          self.bankList = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    //Bank End
    getDisplayDateTime(dt) {
      return moment(dt).format("DD/MM/YYYY HH:mm:ss");
    },
    submitNewMessage() {
      var self = this;
      if (self.remark == "") return;
      var remarks = {
        stockId: self.obj.id,
        remark: self.remark,
      };
      self.api
        .updateRemarks(remarks)
        .then((response) => {
          self.resetObj();
          self.remark = "";
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    updateStockStatus() {
      var self = this;
      var stockStatusInfo = {
        stockId: self.obj.id,
        stockStatusId: self.selectedStockStatusId,
      };
      self.api
        .updateStockStatus(stockStatusInfo)
        .then((response) => {
          self.toast("Message", "Updated", "success");
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    refreshStockStatus() {
      var self = this;
      self.loading = false;
      self.stockStatusApi
        .getList()
        .then((response) => {
          for (let i = 0; i < response.result.length; i++) {
            self.stockStatuses.push({
              value: response.result[i].id,
              label: response.result[i].name,
            });

            if (response.result[i].name == "Draft") {
              self.selectedStockStatusId = response.result[i].id;
            }
          }

          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    refreshVehicleType() {
      var self = this;
      self.loading = false;
      self.vehicleTypeApi
        .getList()
        .then((response) => {
          for (let i = 0; i < response.result.length; i++) {
            self.vehicleTypes.push({
              value: response.result[i].id,
              label: response.result[i].name,
            });
          }

          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onSubmit() {
      var self = this;
      // self.obj.packageCategories = self.categoryList;
      // for (let i = 0; i < self.obj.packageCategories.length; i++) {
      //   self.obj.packageCategories[i].brand = null;
      // }
      console.log("self.selectedArrivalState", self.selectedArrivalState);
      if (self.selectedArrivalState)
        self.obj.arrivalState = self.selectedArrivalState;

      self.obj.import.estimateDateOfDeparture = moment(
        self.estimateDateOfDeparture
      ).format();

      self.obj.import.estimateDateOfArrival = moment(
        self.estimateDateOfArrival
      ).format();

      self.obj.import.dateOfBillOfLading = moment(
        self.dateOfBillOfLading
      ).format();

      self.obj.registration.vehicleRegistrationDate = moment(
        self.vehicleRegistrationDate
      ).format();

      self.obj.registrationDate = moment(self.systemRegistrationDate).format();

      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            // console.log(response);
            self.obj = response.result;
            self.$router.push({ path: `/admins/Stock/${response.result.id}` });
            self.reset();
            //self.$router.push({ path: "/admins/StockList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.obj)
          .then((response) => {
            // self.$router.push({ path: `/admins/Stock/${self.obj.id}` });
            self.toast("Info", "Update success", "success");
            // this.$router.go();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.obj = {};
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    refreshDocumentList() {
      var self = this;
      try {
        self.api
          .get(self.obj.id)
          .then((response) => {
            console.log(response.result);
            self.obj.import.billOfLandingDocuments =
              response.result.import.billOfLandingDocuments;
            self.obj.clearance.k8Documents =
              response.result.clearance.k8Documents;
            self.obj.sale.loan.letterOfUndertakingDocuments =
              response.result.sale.loan.letterOfUndertakingDocuments;

            self.obj.registration.jpjEHakMilikDocuments =
              response.result.registration.jpjEHakMilikDocuments;
            self.obj.registration.puspakomB7SlipDocuments =
              response.result.registration.puspakomB7SlipDocuments;
            self.obj.registration.puspakomB2SlipDocuments =
              response.result.registration.puspakomB2SlipDocuments;
            self.obj.registration.jpjEDaftarDocuments =
              response.result.registration.jpjEDaftarDocuments;

            self.jpjHakMilikDocumentList =
              response.result.registration.jpjEHakMilikDocuments;
            self.jpjGeranDocumentList =
              response.result.registration.jpjGeranDocuments;

            self.puspakomB7SlipDocumentList =
              response.result.registration.puspakomB7SlipDocuments;
            self.puspakomB2SlipDocumentList =
              response.result.registration.puspakomB2SlipDocuments;
            self.jpjDaftarDocumentList =
              response.result.registration.jpjEDaftarDocuments;

            self.bolDocumentList =
              response.result.import.billOfLandingDocuments;
            self.k8DocumentList = response.result.clearance.k8Documents;
            self.k1DocumentList = response.result.clearance.k1Documents;
            // console.log("self.obj.clearance.k1Documents",response.result.clearance.k1Documents);
            // console.log("self.k1DocumentList",self.k1DocumentList);
            // console.log("self.k8DocumentList",self.k8DocumentList);

            self.louDocumentList =
              response.result.sale.loan.letterOfUndertakingDocuments;
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } catch (error) {}
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
            self.selectedArrivalState = self.obj.arrivalState;

            self.selectedStockStatusId =
              self.obj.latestStockStatus.stockStatusId;
            // console.log(self.obj.vehicle.vehicleTypeId);
            if (self.obj.vehicle.vehicleTypeId != null)
              self.selectedVehicleTypeId = self.obj.vehicle.vehicleTypeId;

            if (self.obj.apCompany.subCompany != null)
              self.selectedApSubCompany = self.obj.apCompany.subCompany;

            if (self.obj.apCompany.bankAccount != null)
              self.selectedApBankAccount = self.obj.apCompany.bankAccount;

            if (self.obj.vehicle.brand != null)
              self.selectedBrand = self.obj.vehicle.brand;
            if (self.obj.vehicle.model != null)
              self.selectedModel = self.obj.vehicle.model;
            if (self.obj.purchase.supplier != null)
              self.selectedSupplier = self.obj.purchase.supplier;

            if (self.obj.sale.customer != null)
              self.selectedCustomer = self.obj.sale.customer;

            if (self.obj.sale.loan.bank != null)
              self.selectedBank = self.obj.sale.loan.bank;

            self.estimateDateOfDeparture =
              self.obj.import.estimateDateOfDeparture;
            self.estimateDateOfArrival = self.obj.import.estimateDateOfArrival;

            self.dateOfBillOfLading = self.obj.import.dateOfBillOfLading;

            self.jpjHakMilikDocumentList =
              self.obj.registration.jpjEHakMilikDocuments;

            self.jpjGeranDocumentList = self.obj.registration.jpjGeranDocuments;

            self.puspakomB7SlipDocumentList =
              self.obj.registration.puspakomB7SlipDocuments;
            self.puspakomB2SlipDocumentList =
              self.obj.registration.puspakomB2SlipDocuments;
            self.jpjDaftarDocumentList =
              self.obj.registration.jpjEDaftarDocuments;

            self.bolDocumentList = self.obj.import.billOfLandingDocuments;
            self.k8DocumentList = self.obj.clearance.k8Documents;
            self.k1DocumentList = self.obj.clearance.k1Documents;

            self.louDocumentList =
              self.obj.sale.loan.letterOfUndertakingDocuments;
            self.administrativeCostList =
              self.obj.adminitrativeCost.adminitrativeCostItems;
            self.expensesList = self.obj.expense.expenses;
            self.arrivalChecklistList =
              self.obj.arrivalChecklist.arrivalChecklists;

            self.vehicleRegistrationDate =
              self.obj.registration.vehicleRegistrationDate;

            self.systemRegistrationDate = self.obj.registrationDate;

            if (self.obj.vehicle.vehiclePhotoList.length > 0) {
              if (self.obj.vehicle.vehiclePhotoList[0].position == 0) {
                for (
                  var i = 0;
                  i < self.obj.vehicle.vehiclePhotoList.length;
                  i++
                ) {
                  self.obj.vehicle.vehiclePhotoList[i].position = i + 1;
                }
              }
            }

            // console.log(self.obj.vehicle.vehiclePhotoList);

            // self.updatLatestStockStatus(self.obj.latestStockStatus);
            // if (self.obj.packageCategories.length == 0) {
            // } else {
            //   self.categoryList = self.obj.packageCategories;
            // }
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();

        this.api
          .getNextStockNumber()
          .then((response) => {
            self.obj.stockNo = response.result;
          })
          .catch(({ data }) => {});
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];

      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },
    reset() {
      // this.obj = this.getEmptyObj();
      this.resetObj();
      this.submitted = false;
      this.$v.$reset();
    },
    getEmptyObj() {
      return {
        stockNo: "",
        vehicle: {
          chasisNo: "",
        },
        purchase: {
          supplier: {
            name: "",
          },
        },
        import: {
          shipName: "",
        },
        clearance: {
          approvedPermitNo: "",
        },
        sale: {
          customer: {
            name: "",
          },
          depositAmount: 0,
          loan: {
            depositAmount: 0,
            bank: {
              name: "",
            },
          },
        },
        pricing: {
          recommendedSalePrice: 0,
        },
        registration: {
          racNo: "",
        },
      };
    },
    submit() {
      if (this.isValid) {
        this.onSubmit();
        this.submitted = true;
      }
    },
    cancel() {
      this.$router.push({ path: "/admins/StockList" });
    },
  },
};
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px; /* Space between images */
}

.image-container {
  position: relative; /* Position relative to place buttons over the image */
  width: 100%;
  max-width: 200px; /* Set maximum width for images */
}

.image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Add some border radius for aesthetics */
}

.button-overlay {
  position: absolute; /* Position absolute to overlay on the image */
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Center buttons in the middle of the image */
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap into two columns */
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
  gap: 5px; /* Space between buttons */
  opacity: 0; /* Hide buttons by default */
  transition: opacity 0.3s; /* Smooth transition for hover effect */
}

.image-container:hover .button-overlay {
  opacity: 1; /* Show buttons on hover */
}

.button-row {
  display: flex; /* Display buttons in a row */
  gap: 5px; /* Space between buttons */
}

.move-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%; /* Circular buttons */
}

.maximized-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 8px; /* Optional: Add some border radius for aesthetics */
}
.default-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: yellow;
  color: black;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: bold;
}
</style>


<!-- 
<style scoped>
.image-container {
  position: relative;
  width: 100%;
}

.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.action-button {
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: yellow;
  color: black;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: bold;
}
</style> -->
<!-- 
<style scoped>
.image-container {
  position: relative;
  width: 100%;
}

.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.action-button {
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: yellow;
  color: black;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: bold;
}
</style> -->